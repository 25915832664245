import { Component, OnInit, ElementRef } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { ApiService } from "src/app/controllers/api-service/api-service.service";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ToastrService } from 'ngx-toastr';
import { __values } from 'tslib';
declare var $: any;

@Component({
  selector: 'app-consumer-detail-form',
  templateUrl: './consumer-detail-form.component.html',
  styleUrls: ['./consumer-detail-form.component.css']
})

export class ConsumerDetailFormComponent implements OnInit {
    phonenumber:number = 0;
    cities:any = [];
	states:any = [];
	state:string = '';
    loader:boolean = false;
	phone:string = "";
	username:string = "";
	age:any = null;
	city:string = "";
	pincode:any = '';
	house_name:string = '';
	road_name:string = '';
	landmark:string = '';
    winners:number = 0;

	constructor( private el: ElementRef,private router: Router,activatedRoute:ActivatedRoute,private http: HttpClient,private apiService: ApiService,public toastr:ToastrService) {
        
		activatedRoute.params.subscribe((params) => {
            this.phonenumber = params["phone"];
        });
		if(this.phonenumber){
			setTimeout(()=>{
				this.getWinnerUserList();
			},1000)
		}
    }

  	ngOnInit() {
		this.getStateList();
		this.getCityList(0);
   	}

    numberMobile(e){
	    e.target.value = e.target.value.replace(/[^\d]/g,'');
	    return false;
	}

    IsNumeric(evt) {
		var charCode = (evt.which) ? evt.which : evt.keyCode
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	      /*if (charCode == 46) {
	          return true;
	      }
	      else {*/
	      return false;
	      // }
	    }
	    return true;
    }

	getPincodeList() {
		this.apiService.get("pincode/" + this.pincode, true).subscribe(
		  (response: any) => {
			if (response.success == 1) {
				this.state = response.data.state_id;
				this.getCityList(this.state);
				setTimeout(() => {
					this.city = response.data.city_id;
				}, 1000);
			}else {
				if(this.pincode.length >= 6){
					this.toastr.error('Pincode not found');
				}
			}
		  },
		  (err) => {
			console.error(err);
		});
	}

	onKeypressEvent(e){
		this.pincode = e.target.value;
		if(e.target.value.length == 6){
			this.getPincodeList();
		}
	}
	
    getStateList() {
		this.apiService.get("state/101", true).subscribe(
		  (response: any) => {
			if (response.success == 1) {
			   this.states = response.data;
			}
		  },
		  (err) => {
			console.error(err);
		});
	}

	onChangeState() {
		this.city = '';
		this.getCityList(this.state);
	}

	getCityList(stateId) {
		this.apiService.get("city/" + stateId, true).subscribe(
		    (response: any) => {
				if (response.success == 1) {
				this.cities = response.data;
				}
		    },
			(err) => {
				console.error(err);
		});
	}

	getWinnerUserList() {
		this.apiService.get("winners/" + this.phonenumber, true).subscribe(
		    (response: any) => {
				if (response.success == "1") {
                    this.winners = 1;
					this.phone = response.data.phone;
					this.username = response.data.name;
					this.age = response.data.age;
					if(response.data.is_address_updated){
                        this.phone = response.data.phone;
					    this.username = response.data.name;
					    this.age = response.data.age;
					    this.pincode = response.data.pincode;
					    this.house_name = response.data.house_name;
					    this.road_name = response.data.area;
					    this.landmark = response.data.landmark;
                        this.state = response.data.state_id;
                        this.getCityList(response.data.state_id);
						setTimeout(() => {
							this.city = response.data.city_id;
						}, 1000);
                        //this.toastr.success('Form Details Already Updated');
					}else {
                        
					}
					
				}else {
					this.winners = 0;
				}
		    },
			(err) => {
				console.error(err);
		});
	}

    updateUserFormAddress(){
		if(this.username.trim().length <= 0){
			this.toastr.error("Username is required");
			return;
		}
		if(this.pincode <= 0){
			this.toastr.error("Pincode is required");
			return;
		}
		if(this.pincode.length < 6){
			this.toastr.error("Pincode must be 6 digit");
			return;
		}
		if(this.house_name.trim().length <= 0){
			this.toastr.error("House number is required");
			return;
		}
		if(this.road_name.trim().length <= 0){
			this.toastr.error("Area is required");
			return;
		}
		if(this.landmark.trim().length <= 0){
			this.toastr.error("Landmark is required");
			return;
		}
		if(this.state.length <= 0){
			this.toastr.error("State is required");
			return;
		}
		if(this.city.length <= 0){
			this.toastr.error("City is required");
			return;
		}
		if(this.age == ''){
			this.toastr.error("Age is required");
			return;
		}
		let state; 
		let city;
		
		let index = this.states.findIndex((x:any) => x.id == this.state);
		if(index != -1){
			state = this.states[index].name;
		}

		let index1 = this.cities.findIndex((x:any) => x.id == this.city);
		if(index1 != -1){
			city = this.cities[index1].name;
		}

		this.loader = true;
		this.apiService.post('update/address/',{
    		name:this.username,
	    	age:this.age,
	    	city_id:this.city,
	    	pincode:this.pincode,
	    	house_name:this.house_name,
			road_name:this.road_name,
			landmark:this.landmark,
	    	phone: this.phone,
			state_id: this.state,
			state: state,
			city: city
    	}, false).subscribe((response: any) => {
    		this.loader = false;
    		this.toastr.success("Form submitted successfully");
    		this.getWinnerUserList();

	    },
		(error) => {
			this.loader = false;
			console.error(error);
		});
	}

}