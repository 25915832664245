import { Component, OnInit,} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent implements OnInit{
  title = 'dabur';
  is_login: boolean = false;
  loading:boolean =true;


  constructor(public router: Router,private http: HttpClient, public activatedRoute: ActivatedRoute) {
		// let url = window.location.href;
		// console.log(url);  
		// url = url.replace("https://real.trusttags.in/","");
		// console.log(url);
	    // activatedRoute.params.subscribe((params) => {
	    //   this.qrcode = params["qrcode"];
	    // });
	    // console.log(this.qrcode);
	}

	ngOnInit() {
		//this.qrcode = this.qrcode.replace('https://real.trusttags.in/', '');
	}
}
