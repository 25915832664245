<div class="">
  <!-- <app-header></app-header> -->
  <!-- <header class="main-header">
            <div class="container-fluid">
                <div class="row align-items-center">
                    <div class="col-auto pl-0">
                        <button class="btn pink-gradient btn-icon" id="left-menu"><i class="material-icons">widgets</i></button>
                        <a [routerLink]="['/']" class="logo" style="text-decoration: none;"><img src="../../../assets/img/download (8).png" alt=""><span class="text-hide-xs mt-2" style="vertical-align: sub;"><b style="color: #ac2b8a !important;">Dabur</b></span></a>
                    </div>
                </div>
            </div>
        </header> -->
  <div class="loader justify-content-center pink-gradient" *ngIf="loading">
      <div class="align-self-center text-center">
          <!-- <div class="logo-img-loader">
              <img src="assets/images/logo-150.png" alt="" class="logo-image">
          </div> -->
      </div>
    </div>
     <router-outlet></router-outlet>
  <!-- <app-sidebar [hidden]="!is_login"></app-sidebar> -->
  <footer></footer>
</div>


