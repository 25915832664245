import { Injectable } from '@angular/core';
import { environment } from '../../../environments/environment';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  apiURL = environment.apiURL;
  header: any;

  constructor(private http: HttpClient) { }

  getHeaders(isFormData = false) {
    /*this.tokenHeader = {
      observe: "response",
      headers: {        
        "x-access-token": localStorage.getItem("access_token")
      }
    }; */
    console.log("salesPersonId in get headers", localStorage.getItem("salesPersonId"));

    let headers = {
      "x-access-token": localStorage.getItem("access_token"),
      "x-key-salespersonid": localStorage.getItem("salesPersonId"),
      "Accept": "*/*"
    }

    if(isFormData) {
      headers["Content-Type"] = "multipart/form-data"; 
    }

    this.header = new HttpHeaders(headers);
  }

  google_get(path) {
    return this.http.get(
      path
    );
  }
  get(path, /*token = null,*/ isDownloadable = null) {
    this.getHeaders();

    if (isDownloadable) {
      this.header.responseType = 'arraybuffer';
    }
    
    return this.http.get(
      this.apiURL + path,
      //{ observe: "response"/*, headers: this.header*/ }
    );
  }

  login_get(path) {

    return this.http.get(
      this.apiURL + path,
      { observe: "response" }
    );

  }

  post(path, data, isFormData = false/*token = null, isFormData = false*/) {
    //this.getHeaders(isFormData);
    //data.access_token = localStorage.getItem("access_token");
    return this.http.post(
      this.apiURL + path,
      data,
     // { observe: "response"/*, headers: this.header*/ }
    );
  }

  put(path, data, token = null) {
    this.getHeaders();
    data.access_token = localStorage.getItem("access_token");
    return this.http.put(
      this.apiURL + path,
      data,
      //{ observe: "response", headers: this.header }
    );
  }

  delete(path, token = null) {
    this.getHeaders();

    return this.http.delete(
      this.apiURL + path,
      //{ observe: "response", headers: this.header }
    );
  }
}
