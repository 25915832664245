import { Component, OnInit, ElementRef, Input} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { ApiService } from "src/app/controllers/api-service/api-service.service";
import { ToastrService } from 'ngx-toastr';
declare var $: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent implements OnInit{
	loader:boolean = false;
	corosalcounter:number = 0;
	corosalInterval:any = null;
	is_send:boolean = false;
	isHide:number = 0;
	isChecked:boolean = false;
	otpTimer:number = 60;
	interval:any = null;
	answer:number = 0;
	phone:string = "";
	username:string = "";
	age:any = null;
	city:string = "";
	pincode:any = '';
	//address:string = "";
	state:string = '';
	house_name:string = '';
	road_name:string = '';
	landmark:string = '';
	otp:any = "";
	url:any;
	cities:any = [];
	states:any = [];

	constructor(private el: ElementRef,public router: Router,private http: HttpClient, public activatedRoute: ActivatedRoute, private apiService: ApiService,public toastr:ToastrService) {
		this.url = window.location.href;
		//if(this.url == 'https://Hommade.TTags.in/' || this.url == 'https://hommade.ttags.in/'){
			activatedRoute.params.subscribe((params) => {
			    this.url = params["codes"];
				if(this.url?.length != 5 && this.url?.length != 10){
					this.toastr.error("Invalid Code");
					this.isHide = -1;
				}

				if (this.url?.length == 10){
					this.router.navigate(['form-detail/' + this.url]);
				}
			});
		// }else {
		// 	// activatedRoute.params.subscribe((params) => {
		// 	//     this.url = params["codes"];
		// 	// 	if(this.url?.length != 6 && this.url?.length != 10){
		// 	// 		this.toastr.error("Invalid Code");
		// 	// 		this.isHide = -1;
		// 	// 	}
		// 	// 	if (this.url?.length == 10){
		// 	// 		this.router.navigate(['form-detail/' + this.url]);
					
		// 	// 	}
		// 	// });vn
		// 	this.toastr.error("Opps Sorry Somthing Went Wrong!");
		// 	this.isHide = -1;
			
		// }

	}

	ngOnInit() {
		this.corosalInterval =setInterval(()=>{
			$("#cor"+this.corosalcounter).click();
			if(this.corosalcounter == 0){
				this.corosalcounter = 1;
			}else{
				this.corosalcounter = 0;
			}
		},10000)

		this.getStateList();
		this.getCityList(0);
	}

	numberMobile(e){
	    e.target.value = e.target.value.replace(/[^\d]/g,'');
	    return false;
	}

	IsNumeric(evt) {
		var charCode = (evt.which) ? evt.which : evt.keyCode
	    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
	      /*if (charCode == 46) {
	          return true;
	      }
	      else {*/
	      return false;
	      // }
	    }
	    return true;
    }
    timer(){
    	this.otpTimer = 60;
    	this.interval = setInterval(()=>{
    		this.otpTimer = this.otpTimer -1;
    		if(this.otpTimer <0){
    			clearInterval(this.interval);
    			this.otpTimer = 60;
    		}
    	},1000)
    	/*var el = document.getElementById('seconds-counter');
    	var count = 60, timer = setInterval(function() {
		    $("#seconds-counter").html(count--);
		    if(count == 0) clearInterval(timer);
		     	el.innerText = "You have been here for " + count + " seconds.";
		}, 1000);*/
    }

	getPincodeList() {
		this.apiService.get("pincode/" + this.pincode, true).subscribe(
			(response: any) => {
			  if (response.success == 1) {
				  this.state = response.data.state_id;
				  this.getCityList(this.state);
				  setTimeout(() => {
					  this.city = response.data.city_id;
				  }, 1000);
			  }else {
				  if(this.pincode.length >= 6){
					  this.toastr.error('Pincode not found');
				  }
			  }
			},
			(err) => {
			  console.error(err);
		});
	}

	onKeypressEvent(e){
		this.pincode = e.target.value;
		if(e.target.value.length == 6){
			this.getPincodeList();
		}
	}

	getStateList() {
		this.apiService.get("state/101", true).subscribe(
		  (response: any) => {
			if (response.success == 1) {
			   this.states = response.data;
			}
		  },
		  (err) => {
			console.error(err);
		});
	}

	onChangeState() {
		this.city = '';
		this.getCityList(this.state);
	}

	getCityList(stateId) {
		this.apiService.get("city/" + stateId, true).subscribe(
		    (response: any) => {
				if (response.success == 1) {
				this.cities = response.data;
				}
		    },
			(err) => {
				console.error(err);
		});
	}

    otpVerification(){
    	
    	this.loader = true;
	    if(String(this.phone).trim().length == 10){
	    	if(this.interval){
	    		clearInterval(this.interval);
	    	}
	    	this.otp = "";
	    	this.phone = String(this.phone).trim();
		    this.apiService.post('otpsend',{mobile_no: String(this.phone).trim(),code:this.url}, false).subscribe((response: any) => {
		      this.loader = false;
		      if (response.success == 1) {
		      	this.is_send = true;
    			this.timer();
		        this.toastr.success(response.message);
		      }else {
		      	this.is_send = false;
		      	this.toastr.error(response.message);
		      } 

		    },
		    (error) => {
		        this.loader = false;
		        console.error(error);
		    });
	    }else {
	    	this.loader = false;
	        this.toastr.error('Please enter valid phone number');
	        return;
	    }
    }

    addUserDetial(){
    	if(this.username.trim().length <= 0){
			this.toastr.error("Username is required");
			return;
		}
		if(this.pincode <= 0){
			this.toastr.error("Pincode is required");
			return;
		}
		if(this.pincode.length < 6){
			this.toastr.error("Pincode must be 6 digit");
			return;
		}
		if(this.house_name.trim().length <= 0){
			this.toastr.error("House number is required");
			return;
		}
		if(this.road_name.trim().length <= 0){
			this.toastr.error("Area is required");
			return;
		}
		if(this.landmark.trim().length <= 0){
			this.toastr.error("Landmark is required");
			return;
		}
		if(this.state.length <= 0){
			this.toastr.error("State is required");
			return;
		}
		if(this.city.length <= 0){
			this.toastr.error("City is required");
			return;
		}
		if(this.age == ''){
			this.toastr.error("Age is required");
			return;
		}
		let state; 
		let city;
		
		let index = this.states.findIndex((x:any) => x.id == this.state);
		if(index != -1){
			state = this.states[index].name;
		}

		let index1 = this.cities.findIndex((x:any) => x.id == this.city);
		if(index1 != -1){
			city = this.cities[index1].name;
		}

    	this.loader = true;

    	this.apiService.post('fillDetail',{
    		name:this.username,
	    	age:this.age,
	    	city_id:this.city,
	    	pincode:this.pincode,
	    	//address:this.address,
			house_name:this.house_name,
			road_name:this.road_name,
			landmark:this.landmark,
	    	mobile_no: this.phone,
			state_id: this.state,
			state: state,
			city: city,
	    	code:this.url,
	    	otp:this.otp,
    	}, false).subscribe((response: any) => {
    		this.loader = false;
    		this.toastr.success("Registered successfully");
    		$("#popupclose").click();
	    });
    }

    close(){
    	$("#popupclose1").click();
    }

	login() {
		if(String(this.otp).length == 0){
			this.toastr.error("Please enter otp");
			return;
		}
		if(String(this.otp).trim().length != 6){
			this.toastr.error("OTP length should be 6");
			return;
		}
		this.otp = String(this.otp);
		this.loader = true;
	    this.apiService.post('otpVerify',{
	    	mobile_no: this.phone,
	    	answer:this.answer,
	    	code:this.url,
	    	otp:this.otp
	    }, false).subscribe((response: any) => {
	      this.loader = false;
	      if (response.success == 1) {
	      	this.isHide = 1;
	      	$("#openpopup").click();
	      	this.toastr.success(response.message);
	      }else {
	      	this.toastr.error(response.message);
	      } 

	    },
	    (error) => {
	        this.loader = false;
	        console.error(error);
	    });
    }

    submitAnswer(){
    	if(!this.isChecked){
    		this.toastr.error("Please accept terms and conditions");
    		return;
    	}
    	if(this.answer == 0){
    		this.toastr.error("Please select any one option");
    		return;	
    	}
    	this.loader = true;
    	this.apiService.post('fillDetail',{
	    	mobile_no: this.phone,
	    	answer:this.answer,
	    	code:this.url,
	    	otp:this.otp
	    }, false).subscribe((response: any) => {
	    	this.loader = false;
	    	this.toastr.success("Answer submitted successfully");
	    	this.isHide = 2;
	    });
    	
    }

    openTermsAndCondition(){
    	$("#openpopup1").click();
    }
	
}