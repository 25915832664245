<div class="loader_wrapper" *ngIf="loader"><div class="form_loader"></div></div>
<section>
    <div class="container-fulid bg-custome">
      <!-- <img src="../../../assets/loader.gif" *ngIf="loader" style="position: absolute;z-index: 10000000;top: 50%;"> -->
      <div id="myCarousel" class="carousel slide carousel-fade" data-ride="">
        <!-- Wrapper for slides -->
        <div class="carousel-inner">
          <div class="carousel-item active">
            <img
              src="../../../assets/img/Digital Banner_Coconut Milk_Page 1_Final.jpg"
              alt="header-img"
              class="header-img"
            />
          </div>
          <div class="carousel-item">
            <img
              src="../../../assets/img/Digital Banner_Coconut Milk_Page 2_Final.jpg"
              alt="header-img"
              class="header-img"
            />
          </div>
        </div>
        <div class="row m-auto">
          <div class="col-12 d-flex justify-content-center align-items-center">
            <ol class="carousel-indicators m-0">
              <li
                data-target="#myCarousel" id="cor0"
                data-slide-to="0"
                class="btn-dot my-2 mx-1 bg-custome"
              ></li>
              <li
                data-target="#myCarousel" id="cor1"
                data-slide-to="1"
                class="btn-dot my-2 mx-1"
              ></li>
            </ol>
          </div>
        </div>
      </div>
      <div
        class="row m-auto d-flex justify-content-center align-items-center bg-custome-white">
        <div class="text-center" *ngIf="winners == 0"><h5>Ops! sorry No Details Found.</h5></div>
        <div class="col-12 py-3 px-4" *ngIf="winners == 1">
          <div class="row">
            <div class="col-12">
                <div class="row mb-2">
                    <div class="col-12">
                      <label for="" class="font-small">Full name<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="username" name="username" pattern="[a-zA-Z ]*" required  [readonly]='winners.is_address_updated'/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Mobile number<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="10" [(ngModel)]="phone" name="phone" required readonly/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Age<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="2" [(ngModel)]="age" name="age"  (input)="numberMobile($event);" required [readonly]='winners.is_address_updated'/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Pincode<sup class="text-danger">*</sup></label>
                      <input type="number" class="form-control inp-set mb-2" [(ngModel)]="pincode" name="pincode" onkeypress="if(this.value.length==6) return false;" (input)="onKeypressEvent($event)" required [readonly]='winners.is_address_updated'/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Flat, House no., Building name<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="house_name" name="house_name" required [readonly]='winners.is_address_updated'/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Road name, Area, Colony<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="road_name" name="road_name" required [readonly]='winners.is_address_updated'/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Landmark<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="landmark" name="landmark" required [readonly]='winners.is_address_updated'/>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">Town/City<sup class="text-danger">*</sup></label>
                      <!-- <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="city" name="city" pattern="[a-zA-Z ]*" required /> -->
                      <select class="form-control appearance mb-2" placeholder="" id="cit" [(ngModel)]="city" name="city" required [disabled]='winners.is_address_updated'>
                        <option value="">-- Select --</option>
                        <option value={{b.id}} *ngFor="let b of cities">
                          {{b.name}}</option>
                      </select>
                    </div>
                    <div class="col-12">
                      <label for="" class="font-small">State<sup class="text-danger">*</sup></label>
                      <!-- <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="city" name="city" pattern="[a-zA-Z ]*" required /> -->
                      <select class="form-control appearance mb-2" placeholder="" id="stat" [(ngModel)]="state" name="state" (change)="onChangeState()" required [disabled]='winners.is_address_updated'>
                        <option value="">-- Select --</option>
                        <option value={{a.id}} *ngFor="let a of states">
                          {{a.name}}</option>
                      </select>
                    </div>
                    <!-- <div class="col-12">
                      <label for="" class="font-small">Address<sup class="text-danger">*</sup></label>
                      <input type="text" class="form-control inp-set mb-2" maxlength="60" [(ngModel)]="address" name="address" required [readonly]='winners.is_address_updated'/>
                    </div> -->
                    <div class="col-12" *ngIf="!winners.is_address_updated">
                      <p><sup class="text-danger">*</sup> fields are mandatory</p>
                    </div>
                    <div class="col-12 text-center" *ngIf="!winners.is_address_updated">
                      <button class="submit-btn text-white py-2 px-5 mt-2 text-uppercase" type="submit" (click)="updateUserFormAddress()">
                        Submit &nbsp;<i class="fa-solid fa-circle-arrow-right"></i>
                      </button>
                    </div>
                </div>
            </div>
          </div>
          <div class="row py-3">
            <div class="col-12 d-flex justify-content-center pt-5">
              <small style="color: #519f30">Powered by TrustTags</small>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>