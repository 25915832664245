<div class="loader_wrapper" *ngIf="loader"><div class="form_loader"></div></div>
<section *ngIf="isHide == 0">
  <div class="container-fulid bg-custome">
    <!-- <img src="../../../assets/loader.gif" *ngIf="loader" style="position: absolute;z-index: 10000000;top: 50%;"> -->
    <div id="myCarousel" class="carousel slide carousel-fade" data-ride="">
      <!-- Wrapper for slides -->
      <div class="carousel-inner" data-interval="10000">
        <div class="carousel-item active">
          <img
            src="../../../assets/img/Digital Banner_Coconut Milk_Page 1_Final.jpg"
            alt="header-img"
            class="header-img"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/img/Digital Banner_Coconut Milk_Page 2_Final.jpg"
            alt="header-img"
            class="header-img"
          />
        </div>
      </div>
      <div class="row m-auto">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <ol class="carousel-indicators m-0 py-2">
            <li
              data-target="#myCarousel" id="cor0"
              data-slide-to="0"
              class="btn-dot my-2 mx-1 bg-custome"
            ></li>
            <li
              data-target="#myCarousel" id="cor1"
              data-slide-to="1"
              class="btn-dot my-2 mx-1"
            ></li>
          </ol>
        </div>
      </div>

    </div>
    <div
      class="row m-auto d-flex justify-content-center align-items-center bg-custome-white"
    >
      <div class="col-12 py-3 px-4">
        <form action="">
          <!-- <div class="row">
            <div class="col-12 logo-col py-3">
              <div class="login-ui">
                <h3 class="my-0 font-title">
                  <span><i class="fa fa-user-circle-o"></i></span> Login
                </h3>
              </div>
            </div>
          </div> -->
          <div class="row pt-4">
            <div class="col-12">
              <label for="" class="font-small">Please enter your mobile number</label>
              <input type="number" class="form-control inp-set"  placeholder="Mobile Number" [disabled]="is_send" maxlength="10" [(ngModel)]="phone" name="phone" (input)="numberMobile($event);" required />
            </div>
          </div>
          <div class="row" *ngIf="is_send">
            <div class="col-12 pt-4">
              <label for="" class="font-small">Please enter OTP</label>
              <input type="text" class="form-control inp-set" placeholder="Enter OTP" [(ngModel)]="otp" maxlength="6" name="otp" (input)="numberMobile($event);" required />
              <div id='seconds-counter' *ngIf="otpTimer != 60">You have been here for {{otpTimer}} seconds.</div>
              <a href="javascript:void(0);" *ngIf="otpTimer == 60" class="font-ex-small p-2 float-right m-0" (click)="otpVerification()"
                >Resend OTP</a
              >
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 py-5 d-flex justify-content-center align-items-center"
            >
              <button *ngIf="is_send" class="submit-btn text-white py-2 px-5 text-uppercase" [disabled]="otpTimer == 60" type="submit" (click)="login()">
                Next &nbsp;<i class="fa-solid fa-circle-arrow-right"></i>
              </button>
              <button *ngIf="!is_send" class="submit-btn text-white py-2 px-5 text-uppercase" type="submit" (click)="otpVerification()">
                Get OTP
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center pt-5 pb-5">
              <small style="color: #519f30">Powered by TrustTags</small>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section *ngIf="isHide == 1">
  <div class="container-fulid bg-custome">
    <!-- <img src="../../../assets/loader.gif" *ngIf="loader" style="position: absolute;z-index: 10000000;top: 50%;"> -->
    <div id="myCarousel" class="carousel slide carousel-fade" data-ride="">
      <!-- Wrapper for slides -->
      <div class="carousel-inner" data-interval="10000">
        <div class="carousel-item active">
          <img
            src="../../../assets/img/Digital Banner_Coconut Milk_Page 2_Final.jpg"
            alt="header-img"
            class="header-img"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/img/Digital Banner_Coconut Milk_Page 1_Final.jpg"
            alt="header-img"
            class="header-img"
          />
        </div>
      </div>
      <div class="row m-auto">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <ol class="carousel-indicators m-0 py-2">
            <li
              data-target="#myCarousel" id="cor1"
              data-slide-to="0"
              class="btn-dot my-2 mx-1 bg-custome"
            ></li>
            <li
              data-target="#myCarousel" id="cor0"
              data-slide-to="1"
              class="btn-dot my-2 mx-1"
            ></li>
          </ol>
        </div>
      </div>
    </div>
    <div
      class="row m-auto d-flex justify-content-center align-items-center bg-custome-white"
    >
      <div class="col-12">
        <form action="">
          <div class="row">
            <div class="col-12">
              <!-- <div class="contest">
                <h4 class="my-0 font-title" style="font-size: 24px;margin-right: 0px !important;border-bottom: 2px solid #519f30;">
                  <span><i class="fa fa-trophy"></i>&nbsp;</span>Honey Bunny
                  Contest
                </h4>
              </div> -->
              <div class="q1">
                <p class="mt-3 pb-0 p-2 logo-col" style="border-bottom: none !important;display: block;">
                  Select statement (s) that is (are) true for Dabur Hommade Coconut Milk – 
                </p>
              </div>
              <div class="option p-2">
                <input type="radio" name="answer" [(ngModel)]="answer" value="1" /><label class="px-2 m-0"
                  >A. Extract of 2 Creamy Coconuts</label
                >
              </div>
              <div class="option p-2">
                <input type="radio" name="answer" [(ngModel)]="answer" value="2" /><label class="px-2 m-0"
                  >B. Creamy Taste</label
                >
              </div>
              <div class="option p-2">
                <input type="radio" name="answer" [(ngModel)]="answer" value="3" /><label class="px-2 m-0"
                  >C. No Added Sugars</label
                >
              </div>
              <div class="option p-2">
                <input type="radio" name="answer" [(ngModel)]="answer" value="4" /><label class="px-2 m-0"
                  >D. All of the above</label
                >
              </div>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 d-flex align-items-center py-2 ml-2"
              style="color: #fd8006"
            >
              <input type="checkbox" class="mr-2" [(ngModel)]="isChecked" name="isChecked" /><small
                >I have read and agreed with
                <a href="javascript:void(0);" class="text-info" (click)="openTermsAndCondition()"
                  >Terms and Conditions</a
                ></small>
            </div>
          </div>
          <div class="row">
            <div
              class="col-12 py-5 d-flex justify-content-center align-items-center"
            >
              <button class="submit-btn text-white py-2 px-5 text-uppercase" type="submit" (click)="submitAnswer()">
                Submit
              </button>
            </div>
          </div>
          <div class="row">
            <div class="col-12 d-flex justify-content-center pt-3 pb-3">
              <small style="color: #519f30">Powered by TrustTags</small>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</section>

<section *ngIf="isHide == 2">
  <div class="container-fulid bg-custome">
    <!-- <img src="../../../assets/loader.gif" *ngIf="loader" style="position: absolute;z-index: 10000000;top: 50%;"> -->
    <div id="myCarousel" class="carousel slide carousel-fade" data-ride="">
      <!-- Wrapper for slides -->
      <div class="carousel-inner">
        <div class="carousel-item active">
          <img
            src="../../../assets/img/Digital Banner_Coconut Milk_Page 1_Final.jpg"
            alt="header-img"
            class="header-img"
          />
        </div>
        <div class="carousel-item">
          <img
            src="../../../assets/img/Digital Banner_Coconut Milk_Page 2_Final.jpg"
            alt="header-img"
            class="header-img"
          />
        </div>
      </div>
      <div class="row m-auto">
        <div class="col-12 d-flex justify-content-center align-items-center">
          <ol class="carousel-indicators m-0">
            <li
              data-target="#myCarousel" id="cor0"
              data-slide-to="0"
              class="btn-dot my-2 mx-1 bg-custome"
            ></li>
            <li
              data-target="#myCarousel" id="cor1"
              data-slide-to="1"
              class="btn-dot my-2 mx-1"
            ></li>
          </ol>
        </div>
      </div>
    </div>
    <div
      class="row m-auto d-flex justify-content-center align-items-center bg-custome-white"
    >
      <div class="col-12 py-5 px-4">
        <div class="row">
          <div class="col-12">
            <div class="text-center p-3">
              <p class="font-weight-bold text-1">
                Thank you for participating in Dabur Hommade Coconut Milk Scan & Win Contest.
              </p>
            </div>
            <div class="p-2 text-center">
              <p class="text-bold-set text-2">
                The promotion will be valid from 12th August 2022 till 31st October 2022 or till stocks last only.
              </p>
            </div>
            <div class="p-3 text-center pt-2 pb-3">
              <p class="font-weight-bold text-1">Winners will be contacted at the end of the contest period.</p>
            </div>
          </div>
        </div>
        <div class="row py-3">
          <div class="col-12 d-flex justify-content-center pt-5">
            <small style="color: #519f30">Powered by TrustTags</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</section>


<button type="button" id="openpopup" class="btn btn-lg btn-rounded pink-gradient text-uppercase px-4"
  data-toggle="modal" data-target="#exampleModalCenter" hidden>Login
</button>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog  modal-sm modal-dialog-centered " role="document">
        <div class="modal-content box-shadow">
            <button type="button" class="close text-right pr-2" hidden data-dismiss="modal" id="popupclose" aria-label="Close" >
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-header border-0  px-0">
                <div class="col-12">
                    <h5 class="card-title pl-2" style="font-size: 20px;color: #519f30;">Please fill below details:</h5>
                </div>
            </div>
            <div class="modal-body pr-4 pl-4">
                <div class="row mb-2">
                  <div class="col-12">
                    <label for="" class="font-small">Name<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="username" name="username" pattern="[a-zA-Z ]*" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Age<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="2" [(ngModel)]="age" name="age"  (input)="numberMobile($event);" required />
                  </div>
                  <!-- <div class="col-12">
                    <label for="" class="font-small">Pincode</label>
                    <input type="number" class="form-control inp-set mb-2" [(ngModel)]="pincode" name="pincode" onkeypress="if(this.value.length==6) return false;" (input)="onKeypressEvent($event)" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">City<sup class="text-danger">*</sup></label>
                    <select class="form-control appearance mb-2" placeholder="" id="cit" [(ngModel)]="city" name="city" required >
                      <option value="">-- Select --</option>
                      <option value={{b.id}} *ngFor="let b of cities">
                        {{b.name}}</option>
                    </select>
                  </div> -->

                  <div class="col-12">
                    <label for="" class="font-small">Pincode<sup class="text-danger">*</sup></label>
                    <input type="number" class="form-control inp-set mb-2" [(ngModel)]="pincode" name="pincode" onkeypress="if(this.value.length==6) return false;" (input)="onKeypressEvent($event)" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Flat, House no., Building name<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="house_name" name="house_name" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Road name, Area, Colony<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="road_name" name="road_name" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Landmark<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="landmark" name="landmark" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Town/City<sup class="text-danger">*</sup></label>
                    <!-- <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="city" name="city" pattern="[a-zA-Z ]*" required /> -->
                    <select class="form-control appearance mb-2" placeholder="" id="cit" [(ngModel)]="city" name="city" required >
                      <option value="">-- Select --</option>
                      <option value={{b.id}} *ngFor="let b of cities">
                        {{b.name}}</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">State<sup class="text-danger">*</sup></label>
                    <!-- <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="city" name="city" pattern="[a-zA-Z ]*" required /> -->
                    <select class="form-control appearance mb-2" placeholder="" id="stat" [(ngModel)]="state" name="state" (change)="onChangeState()" required >
                      <option value="">-- Select --</option>
                      <option value={{a.id}} *ngFor="let a of states">
                        {{a.name}}</option>
                    </select>
                  </div>

                  <!-- <div class="col-12">
                    <label for="" class="font-small">Address</label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="100" [(ngModel)]="address" name="address" />
                  </div> -->
                  <div class="col-12">
                    <p><sup class="text-danger">*</sup> fields are mandatory</p>
                  </div>
                  <div class="col-12 text-center">
                    <button class="submit-btn text-white py-2 px-5 mt-2 text-uppercase" type="submit" (click)="addUserDetial()">
                      Next &nbsp;<i class="fa-solid fa-circle-arrow-right"></i>
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

<button type="button" id="openpopup1" class="btn btn-lg btn-rounded pink-gradient text-uppercase px-4"
  data-toggle="modal" data-target="#exampleModalCenter1" hidden>terms
</button>
<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog  modal-lg" role="document">
        <div class="modal-content box-shadow">
            <button type="button" class="close text-right pr-2" data-dismiss="modal" id="popupclose1" aria-label="Close" >
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-header border-0  px-0">
                <div class="col-12">
                    <h5 class="card-title mb-0 text-left">Terms & Conditions:</h5>
                    <hr>
                </div>
            </div>
            <div class="modal-body text-center pr-4 pl-4">
                <div class="row mb-2">
                  <div class="col-12">
                    <p style="text-align: justify;">
                      1.Dabur Hommade Coconut Milk, (hereinafter referred to as “ Hommade ”) is initiating SCAN & WIN Contest hereinafter referred to as “the promotion” for easy reference.
                    </p>
                    <p style="text-align: justify;">
                      2.The promotion will be valid from 12th August 2022 till 31st October 2022 or till the stocks last only and the participation in the Promotion is optional & voluntary and does not guarantee any prize.
                    </p>
                    <p style="text-align: justify;">
                      3.To participate in the promotion, one needs to purchase Dabur Hommade Coconut Milk 200ml with a unique QR code printed on back panel. Scanning the QR code leads the consumer to a microsite. Post OTP validation, consumer needs to key in basic personal details (address, contact no.), accept the Terms and Conditions and answer a simple question. The Promotion is applicable for limited period only.
                    </p>
                    <p style="text-align: justify;">
                      4.Stocks without this offer will also be available.
                    </p>
                    <p style="text-align: justify;">
                      5.Participation in this promotion constitute acceptance of the terms and conditions.
                    </p>
                    <p style="text-align: justify;">
                      6.Participants will get a chance to win any one of the 5 types of prizes namely Microwave or Mixer Grinder or Dinner Set or Metal Sipper or Lunch Box. Warranty of the prizes will be the responsibility of the product manufacturer.
                    </p>
                    <p style="text-align: justify;">
                      7.This contest is open for all residents of India (Age >= 18 years).
                    </p>
                    <p style="text-align: justify;">
                      8.On the basis of correct answer given by the participants along with the QR code, entries would be considered as valid entries in the contest. From amongst the valid entries received, winning participants would be selected by Dabur India Limited.
                    </p>
                    <p style="text-align: justify;">
                      9.Winners will be announced at the end of the promotion period.
                    </p>
                    <p style="text-align: justify;">
                      10.The winners will be informed by SMS or by a representative will reach out to them to seek their complete address details and the prizes will be delivered at the same address.
                    </p>
                    <p style="text-align: justify;">
                      11.Selected winners may appear on digital platforms such as Facebook, Instagram, Twitter, blogs, articles etc.
                    </p>
                    <p style="text-align: justify;">
                      12.The participation is purely voluntary & no consideration is payable by Dabur against use of entries / videos received for the Promotion.
                    </p>
                    <p style="text-align: justify;">
                      13.Decision of DABUR shall be final in the selection of Winners and no correspondence(s) will be entertained under any circumstances of whatsoever nature.
                    </p>
                    <p style="text-align: justify;">
                      14.The prizes offered to the winners cannot be redeemed /exchanged for cash and no cash claim shall be entertained.
                    </p>
                    <p style="text-align: justify;">
                      15.Dabur reserves the right to withdraw & / or alter any of the terms & conditions of this event at any time during the event without prior notice.
                    </p>
                    <p style="text-align: justify;">
                      16.Dabur reserves the rights to change the prizes to equivalent options and their quantities in situations of non-availability of the mentioned prizes or issues concerned with procurement or delivery of the same.
                    </p>
                    <p style="text-align: justify;">
                      17.DABUR shall not be liable or responsible under any circumstancesfor any loss, injury or any other liability arising out of the promotion.
                    </p>
                    <p style="text-align: justify;">
                      18.Dabur employees, relatives of Dabur employees and Dabur agencies are not eligible for participation in the contest.
                    </p>
                    <p style="text-align: justify;">
                      19.LIMITATION OF LIABILITY: In no event shall Dabur or its affiliates, and their officers, directors, employees, and agents be liable to Participants or any third party for any direct, indirect, consequential, exemplary, incidental, special, or punitive damages, including lost profit damages arising from Participants participation in the Promotion in any manner. The foregoing limitation of liability shall apply to the fullest extent permitted by law in the applicable jurisdiction and regardless of the form of the action, will at all times be limited to the amount paid, if any, by Participants to Dabur for the promotion. Participants specifically agree and understand that Dabur is not liable to Participants for any content or illegal conduct of any third party and that Participants alone accept such risk of harm.
                    </p>
                    <p style="text-align: justify;">
                      20.INDEMNIFICATION : Participants agree to defend, indemnify and hold harmless Dabur from and against any and all claims, damages, injuries, obligations, losses, liabilities, costs or debt, and expenses (including but not limited to attorney's fees) arising from: (i) Participants use of and access to the promotion; (ii) Participants violation of any term of these Terms. This defense and indemnification obligation will survive these Terms.
                    </p>
                    <p style="text-align: justify;">
                      21.Any provision of the these terms and conditions that are declared invalid or unenforceable by a court of competent jurisdiction shall be ineffective to the extent of such invalidity or unenforceability without invalidating or rendering unenforceable the remaining provisions hereof.
                    </p>
                    <p style="text-align: justify;">
                      22.All disputes are subject to the exclusive jurisdiction of Courts of Delhi alone.
                    </p>
                    <p style="text-align: justify;">
                      23.Dabur can contact the consumer for upcoming events or launches on the contact details provided for which no consideration would be payable.
                    </p>
                    <p style="text-align: justify;">
                      24.Please note that premium SMS charges may apply. However, these rates are not fixed and may be subject to change by each Participant’s concerned mobile service provider. However, we strongly recommend that Participants familiarize, check and confirm the rates for such SMS with their respective telecom service providers before participating in the Promotion.
                    </p>
                    <p style="text-align: justify;">
                      25.If an SMS is received before the commencement of the Promotion Period, the Participant will receive an SMS requesting the Participant to apply during the appropriate period and time. If an SMS is received after the completion of the Promotion Period, the Participant will be informed about the closure of the Promotion.
                    </p>
                    <p style="text-align: justify;">
                      26.If the Participant sends an SMS which is not in the prescribed format, or contains an invalid code, the Participant will receive an SMS informing the Participant of the required format along with a request to reapply.
                    </p>
                    <p style="text-align: justify;">
                      27.Please note that each QR Code printed on the Promotion Product can be used only once for the purpose of participation. However, participants are allowed to submit as many entries as they wish through packs with different QR Codes during the Promotion Period.
                    </p>
                    <p style="text-align: justify;">
                      28.These terms of use (Terms) constitute a legally binding agreement between you and the Company regarding your use of the Site and any services offered by the company including but not limited to delivery of content via the Site, any mobile or internet connected device or otherwise (the "the Service"). By accessing the Site or Service and/or by clicking "I agree", you agree to be bound by these Terms. You hereby represent and warrant to the Company that you are capable of entering, performing and adhering to these Terms and that you agree to be bound by the following terms and conditions. While individuals under the age of 18 may utilize the Service of the site, they shall do so only with the involvement & guidance of their parents and / or legal guardians, under such Parent /Legal guardian's registered account. You agree to register prior to uploading any content and / or comment and any other use or services of this site and provide your details including but not limited to complete name, age, email address, residential address, contact number.
                    </p>
                    <p style="text-align: justify;">
                      29.Dabur reserves the right to disclose your information which will use by third-parties in connection with marketing, promotional and other offers, as well as product information. Dabur may send information and offer products and services to you from time to time. Dabur reserves the right to disclose any information in response to / that it is required to be shared, disclosed or make made available to any governmental, administrative, regulatory or judicial authority under any law or regulation applicable to DABUR. Further, Dabur can (and you authorize Dabur to) disclose your name, street address, city, state, zip code, country, phone number, email, and company name to Intellectual Property right's owners, as we in our sole discretion believe necessary or appropriate in connection with an investigation of fraud, intellectual property infringement, piracy, or other unlawful activity.
                    </p>
                    <p style="text-align: justify;">
                      30.The website of Contest may include links to other websites or applications. Such website/application are governed by their respective privacy policies. Use of any information you provide is governed by the privacy policy of the operator of the application, website you are visiting. That policy may differ from ours. If you can't find the privacy policy of any of these sites via a link from the application's/website homepage, you should contact the application/website owners directly for more information. Participant will indemnify Dabur India Limited against any breach of data privacy or any unauthorized access of Data by VIVIDeas solutions Pvt. Ltd. or any other 3rd Party. By visiting the Site and entering your data to know more about details of any product or services displayed on the Site, you hereby authorize us to share your data with our advertiser in order to give you more information about the product or goods or services. All prizes offered in this contest will be distributed by a designated third party.
                    </p>
                  </div>
                  <div class="col-12 py-5 d-flex justify-content-center align-items-center">
                    <button type="button" class="submit-btn text-white py-2 px-5 text-uppercase" (click)="close()">Close</button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div>

<!-- <button type="button" id="openpopup2" class="btn btn-lg btn-rounded pink-gradient text-uppercase px-4"
  data-toggle="modal" data-target="#exampleModalCenter2" hidden >detail
</button> -->
<!-- <div class="modal fade modal2" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-hidden="true" data-backdrop="false">
    <div class="modal-dialog  modal-md modal-dialog-centered " role="document">
        <div class="modal-content box-shadow">
            <button type="button" class="close text-right pr-2" hidden data-dismiss="modal" id="popupclose2" aria-label="Close" >
                <span aria-hidden="true">×</span>
            </button>
            <div class="modal-header border-0  px-0">
                <div class="col-12">
                    <h5 class="card-title pl-2" style="font-size: 20px;color: #519f30;">Please fill below details:</h5>
                </div>
            </div>
            <div class="modal-body pr-4 pl-4">
                <div class="row mb-2">
                  <div class="col-12">
                    <label for="" class="font-small">Name<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="40" [(ngModel)]="username" name="username" pattern="[a-zA-Z ]*" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Mobile Number<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="10" [(ngModel)]="phone" name="phone" required disabled/>
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Age<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="2" [(ngModel)]="age" name="age"  (input)="numberMobile($event);" required />
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">State<sup class="text-danger">*</sup></label>
                    <select class="form-control" placeholder="" [(ngModel)]="state" name="state" (change)="onChangeState()" required>
                      <option value="">-- Select --</option>
                      <option value={{a.id}} *ngFor="let a of states">
                        {{a.name}}</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">City<sup class="text-danger">*</sup></label>
                    <select class="form-control" placeholder="" [(ngModel)]="city" name="city" required>
                      <option value="">-- Select --</option>
                      <option value={{b.id}} *ngFor="let b of cities">
                        {{b.name}}</option>
                    </select>
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Pincode</label>
                    <input type="text" class="form-control inp-set mb-2" [(ngModel)]="pincode" name="pincode"  (input)="numberMobile($event);" maxlength="6" required/>
                  </div>
                  <div class="col-12">
                    <label for="" class="font-small">Address<sup class="text-danger">*</sup></label>
                    <input type="text" class="form-control inp-set mb-2" maxlength="60" [(ngModel)]="address" name="address" required />
                  </div>
                  <div class="col-12">
                    <p><sup class="text-danger">*</sup> fields are mandatory</p>
                  </div>
                  <div class="col-12 text-center">
                    <button class="submit-btn text-white py-2 px-5 mt-2 text-uppercase" type="submit" (click)="updateUserFormAddress()">
                      Submit &nbsp;<i class="fa-solid fa-circle-arrow-right"></i>
                    </button>
                  </div>
                </div>
            </div>
        </div>
    </div>
</div> -->




 

 

 

 












 









 



 




