import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { LoginComponent } from './controllers/login/login.component';
import { ConsumerDetailFormComponent } from './controllers/Consumer-detail-form/Consumer-detail-form.component';
import { CodesComponent } from './controllers/codes/codes.component';

const routes: Routes = [
  // {
  //   path: ':codes',
  //   component: CodesComponent,
  //   //canActivate: [AuthenticationGuard],
  //   data: {
  //     title: "Trusttags::Home",
  //     signin: true
  //   }
  // },
  {
    path: ':codes',
    component: LoginComponent,
    //canActivate: [AuthenticationGuard],
    data: {
      title: "Trusttags::Home",
      signin: true
    }
  },
  {
    path: 'form-detail/:phone',
    component: ConsumerDetailFormComponent,
    //canActivate: [AuthenticationGuard],
    data: {
      title: "Trusttags::Home",
      signin: true
    }
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
