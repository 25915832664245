import { Component, OnInit,} from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { HttpClient, HttpHeaders } from "@angular/common/http";

@Component({
  selector: 'app-codes',
  templateUrl: './codes.component.html',
  styleUrls: ['./codes.component.css']
})
export class CodesComponent implements OnInit{

	url:any;

	constructor(public router: Router,private http: HttpClient, public activatedRoute: ActivatedRoute) {
		 this.url = window.location.href;
		console.log(this.url);  
		this.url = this.url.replace("https://real.trusttags.in/","");
		this.url = this.url.replace("https://","")
	 	this.url = this.url.replace("http://","")
	 	this.url = this.url.replace("localhost:4200/","")
	 	this.url = this.url.replace("Real.TrustTags.in/","")
	 	this.url = this.url.replace("real.trustTags.in/","")
		//this.url = this.url.replace("https://192.168.179.19:4200/","");
		console.log(this.url);
	    // activatedRoute.params.subscribe((params) => {
	    //   this.qrcode = params["qrcode"];
	    // });
	    // console.log(this.qrcode);
	}

	ngOnInit() {
		//this.qrcode = this.qrcode.replace('https://real.trusttags.in/', '');
	}
}
